import React from 'react'
import InsuranceComponent from '..';
import MedicalInsuranceComponent from './MedicalInsuranceComponent';

const GapMedicalInsurance = ({ updateInsuranceQuotes, allPrograms, camperList, insuranceState, userId, userEmail, userName, userLastName, billingDetail }) => {
    return (
        <InsuranceComponent
            client-id="live_OS3ZRXTLX720CFG3PYVR9NW7AC4RODA9"
            offerStateChange={updateInsuranceQuotes.bind(this)}
            product="gap-medical"
            style={{ display: 'none', width: '100%' }}
        >
            {allPrograms.map((cartItem, cartIndex) => {
                const campers = cartItem.campers;
                const eventDates = cartItem.additionalDates?.filter(el => cartItem.selectedDates.find((id) => el._id === id))?.flatMap(d => [d.startDate, d.endDate])?.sort(function (a, b) { return a - b; })
                let startDate;
                let endDate;

                if (eventDates && eventDates.length > 0) {
                    startDate = new Date(parseInt(eventDates[0])).toISOString().substring(0, 10);
                    endDate = new Date(parseInt(eventDates[eventDates.length - 1])).toISOString().substring(0, 10);
                } else {
                    startDate = new Date(cartItem.startDate).toISOString().substring(0, 10);
                    endDate = new Date(cartItem.endDate).toISOString().substring(0, 10);
                }

                return campers.map(camper => {
                    const camperDetails = camperList.find(c => c._id === camper.camperId);
                    const insuranceItem = (
                        <MedicalInsuranceComponent
                                key={`out-gap-medical-insurance-offer-${cartItem._id}-${camper._id}`}
                                item={cartItem}
                                camper={camper}
                                camperDetails={camperDetails}
                                userName={userName}
                                userEmail={userEmail}
                                zipCode={camper?.zipCode}
                                insuranceState={insuranceState}
                                userLastName={userLastName}
                                startDate={startDate}
                                endDate={endDate}
                                userId={userId}
                                camperId={camper._id}
                            />
                    );

                    return insuranceItem;
                })
            })}
        </InsuranceComponent>
    )
}

export default GapMedicalInsurance
