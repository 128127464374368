import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import InputMask from 'react-input-mask';
import { OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import { DatePicker, Select } from 'antd';
import PaypalExpressBtn from 'react-paypal-express-checkout';
import { Link } from 'react-router-dom';
import { AES } from 'crypto-js';

import { getCustomerCardsService, createCustomerCardService, bookingCreate } from '../../services/programService';
import { getCampDetailService } from '../../services/userServices';
import { InformationIcon } from '../reuse/SVG';
import { getitemsincart } from '../../store/cart';
import { calculateEMIPaymentDates, hasSplitOption } from '../../helper';
import moment from 'moment';
import settings from '../../config/settings';
import { curry } from 'lodash';

const dataLayer = window.dataLayer;

const { Option } = Select;

const client = {
    sandbox: 'AXaWld2Rdu8bCqRhH_Kqv908CBHk_OUI8y7FSbFIlj-g0t1i43YGr4cvIJI27rp6GXlDg3rjoUjpDgtE',
    production: 'AXNTAk_nrqYLg_CdNlIDDksbg9E_Q1gkK-tFUdupEf4i1zA0BKUL1RKtTfEk2j4rKGaWfnBYWlpsGphh',
}


const getCardTypeName = type => {
    switch (type) {
        case 'American Express':
            return 'AmericanExpress';
            break;
        case 'Visa Electron':
        case 'visa':
            return 'Visa';
            break;
        case 'mastercard':
        case 'Mastercard':
            return 'MasterCard';
            break;
        case 'discover':
        case 'Discover':
            return 'Discover';
            break;
        case 'china_unionpay':
        case 'ChinaUnionpay':
            return 'Unionpay';
            break;
        case 'Diners Club':
            return 'DinersClub';
            break;
        default:
            return 'Visa';
    }
}

const addZero = num => {
    if( num.toString().length < 2 )
    return "0" + num;
    return num.toString();
}



const getWithTaxPrice = (obj, amount) => {
    let withTax = 0
    if(obj.percentage){
        withTax = amount + ((amount * obj.percentage) / 100) + obj.taxRate;
    }else{
        withTax = amount + obj.taxRate;
    }
 
    return withTax.toFixed(2);
}
const getInsuranceWithTaxPrice = (obj, amount) => {
    let withTax = 0
    if(obj.percentage){
        withTax = amount + ((amount * obj.percentage) / 100);
    }else{
        withTax = amount;
    }
    return withTax.toFixed(2);
}

const getSplitPrice = (obj, amount) => {
    let _amount = amount / (obj.noOfTimesPayment || 1);
    let withTax = 0
    if(obj.percentage){
        withTax = _amount + ((_amount * obj.percentage) / 100) + obj.taxRate;
    }else{
        withTax = _amount;
    }
 
    return withTax.toFixed(2);
}

const getSplitPrice2 = (obj, amount) => {
    let _amount = amount / (obj.noOfTimesPayment || 1);
    return _amount.toFixed(2);
}




const PaymentGateway = props => {
    const [programStartDate, setProgramStartDate] = useState(props.cartItems[0].startDate);
    const [btnloder, setBtnloder] = useState(false);
    const [successModel, setSuccessModel] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [allErrorMsg, setAllErrorMsg] = useState('');

    const [paymentMethod, setPaymentMethod] = useState(0);
    const [paymentMethodErr, setPaymentMethodErr] = useState('');
    const [isNewCard, setIsNewCard] = useState(false);
    const [cardList, setCardList] = useState([]);


    const [addCardBtnLoader, setAddCardBtnLoader] = useState(false);
    const [cardNo, setCardNo] = useState('');
    const [cardNoErr, setCardNoErr] = useState('');
    const [expDate, setExpDate] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [expDateErr, setExpDateErr] = useState('');
    const [cvc, setCvc] = useState('');
    const [cvcErr, setCvcErr] = useState('');
    const [firstName, setFirstName] = useState('');
    const [firstNameErr, setFirstNameErr] = useState('');
    const [lastName, setLastName] = useState('');
    const [resErr, setResErr] = useState('');
    const [creditCardRate, setCreditCardRate] = useState({});
    const [achTransferRate, setAchTransferRate] = useState({});
    const [byCheque, setByCheque] = useState({});
    const [splitPaymentRate, setSplitPaymentRate] = useState({});

    const [customerPaymentProfileId, setCustomerPaymentProfileId] = useState('');
    const [customerPaymentProfileIdError, setCustomerPaymentProfileIdError] = useState('');
    const [customerProfileId, setCustomerProfileId] = useState('');


    const [bankAccountType, setBankAccountType] = useState('');
    const [bankAccountNum, setBankAccountNum] = useState('');
    const [routingNumber, setRoutingNumber] = useState('');
    const [nameOnAccount, setNameOnAccount] = useState('');
    const [bankAccountTypeErr, setBankAccountTypeErr] = useState('');
    const [bankAccountNumErr, setBankAccountNumErr] = useState('');
    const [routingNumberErr, setRoutingNumberErr] = useState('');
    const [nameOnAccountErr, setNameOnAccountErr] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [zipCodeErr, setZipCodeErr] = useState('');



    const getTaxs = () => {
        let query = '';
        if(props?.cartItems?.length == 1){
            query = `?campCode=${props.allCampCodes[0]}&programId=${props.cartItems[0].programId}`;
        }else{
            query = `?campCode=${props.allCampCodes[0]}`;
        }
        getCampDetailService(query).then(res => {
            if(res?.data?.statusCode){
              let _data = res.data.responseData.result;
              setCreditCardRate(_data.creditCardRate || {});
              setAchTransferRate(_data.achTransferRate || {});
              setByCheque(_data.byCheque || {});
              setSplitPaymentRate(_data.splitPaymentRate || {});
            }
        })
    }

    useEffect(() => {
        if(props.allCampCodes.length){
            getTaxs();
        }
      
    }, [props.allCampCodes])





    const getCards = () => {
        let query = `?isWeb=1&campCode=${props.allCampCodes[0]}`
        getCustomerCardsService(query).then(res => {
            if (res.data.statusCode) {
                if(res.data.responseData.result.length){
                    setCardList(res.data.responseData.result ? res.data.responseData.result : []);
                    setCustomerProfileId(res.data.responseData.result ? res.data.responseData.result[0].card.signature : '');
                }else{
                    setIsNewCard(true);
                }
            } else {
                setCardList([]);
            }
        })
    }

    useEffect(() => {
        if(props?.allCampCodes?.length && props?.allCampCodes?.length){
            getCards();
        }
      
    }, [props.isNewCardAdd, props.allCampCodes])




    const handleAddCardSubmint = () => {
        let validate = true;
        setResErr('');
        if(cardNo == ''){
            setCardNoErr('card number error');
            validate = false;
        }else if (!(cardNo.length == 18 || cardNo.length == 19)) {
            setCardNoErr('card number lenth error');
            validate = false;    
        }else if(cardNo.includes('_')){
            setCardNoErr('card number lenth error');
            validate = false;    
        }

        if(expDate == ''){
            setExpDateErr('card expired date error');
            validate = false;
        }
        if(zipCode == ''){
            setZipCodeErr('zip code error');
            validate = false;
        }

        if(cvc == ''){
            setCvcErr('cvc error');
            validate = false;
        }else if(cvc.includes('_')){
            setCvcErr('cvc lenth error');
            validate = false;    
        }

        if(firstName == ''){
            setFirstNameErr('first name error');
            validate = false;
        }else if(firstName.length > 50){
            setFirstNameErr('First name is to large max limit 50')
            validate = false;
        }

        if(validate){
            setAddCardBtnLoader(true);
            let _cardNo = cardNo.replace(/\s/g,'');
            const encrypted_cardNo = AES.encrypt(_cardNo, settings.api.mySecretKey).toString();
            const encrypted_cvc = AES.encrypt(cvc, settings.api.mySecretKey).toString();
            let params = {
                cardNo: encrypted_cardNo,
                expDate,
                month,
                year,
                cvc: encrypted_cvc,
                firstName,
                lastName,
                email: props.userProfile.email,
                isWeb: 1,
                campCode: props.allCampCodes,
                zipCode
            }

           
            createCustomerCardService(params).then(res => {
                setAddCardBtnLoader(false);
                if(res?.data?.statusCode){
                    getCards();
                    setCardNo('');
                    setExpDate('');
                    setCvc('');
                    setFirstName('');
                    setLastName('');
                    setIsNewCard(false);
                }else{
                    setResErr(res.data.error.errors.message || res.data.error.responseMessage)
                }
                
            })
        }
    }





    const callPaypalService = paypalData => {
        setBtnloder(true);
        let billingDetail = {...props.billingDetail};
        let params = {};
        let cartIds = props.cartIdsarr;

        params.cartIds = JSON.stringify(cartIds);
        params.billingDetail = JSON.stringify(billingDetail);
        params.isWeb = 1;

        if (props.copuonObj.copuonValue) {
            params.couponCode = props.copuonObj.copuonName;
            params.couponValue = props.copuonObj.copuonValue;
            params.discountArr = props.copuonObj.discountArr;
        }

        if (props.giftCartObj.giftCartValue) {
            params.giftCardCode = props.giftCartObj.giftCartName;
            params.giftCardValue = props.giftCartObj.giftCartValue
        }

        params.authPayment = JSON.stringify({paymentType: 5})
        params.paypalData = JSON.stringify(paypalData)

        params.payment = JSON.stringify({
            method: 'Paypal',
            amount: props.grandtotal.toFixed(2),
            transaction_id: paypalData.paymentID,
            redeemableAmount: props.grandtotal.toFixed(2),
            status: 2
        })

        bookingCreate(params).then(res => {
            if (res.data.statusCode == 1) {
                setBtnloder(false);
                props.getitemsincart(props.itemsincart - 1);
            } else {
                setBtnloder(false);
                setErrorMsg(res.data.error.errors.message || res.data.error.responseMessage);
            }
        })
    }



    const onSuccess = (payment) => {
        callPaypalService(payment);
    }

    const onCancel = (data) => {}

    const onError = (err) => {
        console.error("Error!", err);
    }



    const handleValidate = () => {
        let validate = true;

        if(props.grandtotal > 0){
            if(paymentMethod == 0){
                validate = false;
                setPaymentMethodErr('Please select payment method');
            }
    
            if(paymentMethod == 1){
                if(customerPaymentProfileId == '' || customerPaymentProfileId == 'undefined' || customerPaymentProfileId == null){
                    validate = false;
                    setCustomerPaymentProfileIdError('Please select card')
    
                }
            }


            if(paymentMethod == 3) {

                if(bankAccountType == '' || bankAccountType == 'undefined' || bankAccountType == null){
                    validate = false;
                    setBankAccountTypeErr('Account type is required')
                }

                if(bankAccountNum == '' || bankAccountNum == 'undefined' || bankAccountNum == null){
                    validate = false;
                    setBankAccountNumErr('Account number is required')
                }

                if(routingNumber == '' || routingNumber == 'undefined' || routingNumber == null){
                    validate = false;
                    setRoutingNumberErr('Routing number is required')
                }

                if(nameOnAccount == '' || nameOnAccount == 'undefined' || nameOnAccount == null){
                    validate = false;
                    setNameOnAccountErr('Account holder name is required')
                }

            }
        }



        return validate;
    }





    const submitRegistration = async (e) => {
        e.preventDefault();
        setErrorMsg(undefined);

        if (!(await props.insuranceOfferValidator())) {
            setErrorMsg("Please either select or decline insurance options.");
            return false;
        }

        if (handleValidate()){
            
            setBtnloder(true);

            let billingDetail = {...props.billingDetail};
            let cartIds = props.cartIdsarr;

            let params = {};

            params.isWeb = 1;

            params.billingDetail = JSON.stringify(billingDetail);
            params.cartIds = JSON.stringify(cartIds);
            params.isWeb = 1;

    
            params.isWeb = 1;

            if(props.is_payment_split == 1){
                params.is_payment_split = props.is_payment_split;
            }

            if (props.copuonObj.copuonValue) {
                params.couponCode = props.copuonObj.copuonName;
                params.couponValue = props.copuonObj.copuonValue;
                params.discountArr = props.copuonObj.discountArr;
            }
    
            if (props.giftCartObj.giftCartValue) {
                params.giftCardCode = props.giftCartObj.giftCartName;
                params.giftCardValue = props.giftCartObj.giftCartValue
            }


            if(props.grandtotal > 0){

                if(props.insuranceTotal){
                    params.insurance = props.insuranceTotal

                    let insuranceQuotes = Object.values(props.insuranceQuotes);
                    if (insuranceQuotes.length > 0) {
                      params.insuranceQuotes = insuranceQuotes.map(q => ({quote_id: q.quote.quote_id, premium_amount: q.quote.total, type: q.type}))
                    } 
                }

                    let _payment = {};


                if(paymentMethod == 1) {
                    _payment.method = 'Credit Card';
                    _payment.creditCardRate = {percentage: creditCardRate.percentage,taxRate:  creditCardRate.taxRate}
                    //_payment.amount = getWithTaxPrice(creditCardRate, props.grandtotal + props.insuranceTotal);
                    _payment.amount = getWithTaxPrice(creditCardRate, props.grandtotal);
                    params.authPayment = JSON.stringify({
                        paymentType: paymentMethod,
                        customerProfileId,
                        customerPaymentProfileId,
                        token: customerPaymentProfileId
                    });
                }

                if(paymentMethod == 3) {

                    const __routing_number = AES.encrypt(routingNumber, settings.api.mySecretKey).toString();
                    const __bankAccountNum = AES.encrypt(bankAccountNum, settings.api.mySecretKey).toString();


                    _payment.method = 'Internet Banking';
                    _payment.achTransferRate = {percentage: achTransferRate.percentage,taxRate:  achTransferRate.taxRate}
                    //_payment.amount = getWithTaxPrice(achTransferRate, props.grandtotal + props.insuranceTotal);
                    _payment.amount = getWithTaxPrice(achTransferRate, props.grandtotal);
                    params.internetBanking = 1;

                    params.authPayment = JSON.stringify({
                        account_owner_name: nameOnAccount,
                        routing_number: __routing_number,
                        account_number: __bankAccountNum,
                        account_type: bankAccountType,
                        account_owner_type: 'individual',
                        country: 'US',
                        currency: 'usd',
                        paymentType: 4
                    });
                    
                }

                if(paymentMethod == 4) {
                    _payment.method = 'Check';
                    _payment.byCheque = {taxRate:  byCheque.taxRate}
                    _payment.amount = getWithTaxPrice(byCheque, props.grandtotal);
                    _payment.status = 2;
                    params.authPayment = JSON.stringify({
                        paymentType: 5
                    });
                }

                if(paymentMethod == 5) {
                    _payment.method = 'Split Payment';
                    _payment.amount = getSplitPrice(splitPaymentRate, props.grandtotal);
                    _payment.splitPaymentRate = {percentage: splitPaymentRate.percentage,taxRate:  splitPaymentRate.taxRate}
                    //_payment.amount = getSplitPrice(splitPaymentRate, props.grandtotal + props.insuranceTotal);
                    params.authPayment = JSON.stringify({
                        paymentType: 1,
                        customerProfileId,
                        customerPaymentProfileId,
                        token: customerPaymentProfileId
                    });


                    let _allEmiRemains = calculateEMIPaymentDates(props.cartItems[0].startDate, (splitPaymentRate.noOfTimesPayment - 1), getSplitPrice2(splitPaymentRate, props.grandtotal));
            

                    params.remainingAmount = props.grandtotal - getSplitPrice2(splitPaymentRate, props.grandtotal);

                    if(params.remainingAmount > 0){
                        params.remainingAmount = params.remainingAmount.toFixed(2)
                    }


                    params.isEmi = JSON.stringify({
                        status : 1,
                        month : splitPaymentRate.noOfTimesPayment,
                        remaingMonth: splitPaymentRate.noOfTimesPayment - 1,
                        allEmiRemains: _allEmiRemains
                    })

                }


                params.customerPaymentProfileId = customerPaymentProfileId;
                params.payment = JSON.stringify(_payment);



                
            }


           
    
            bookingCreate(params).then(res => {
                if (res.data.statusCode == 1) {
                    setSuccessModel(true);
                    setBtnloder(false);
                    props.getitemsincart(props.itemsincart - 1);
                    dataLayer.push({
                        currency: "USD",
                        event: 'sport_international_checkout',
                        order_id: res.data.responseData.result.bookingNumber, 
                        value: props.grandtotal - props.totalProductsPrice,
                        email: res.data.responseData.email,
                        offer_code: res.data.responseData.result.couponCode || ''
                     })
                  
                } else {
                    setBtnloder(false);
                    setErrorMsg(res.data.error.errors.message || res.data.error.responseMessage);
                }
            })
            
        } else {
            setBtnloder(false);
            //setAllErrorMsg('Billing information is required');
        }
    }


    const handleCardNumberChange = (e) => {
        const inputNumber = e.target.value.replace(/\D/g, '');
        const formattedNumber = inputNumber.replace(/(\d{4})/g, '$1 ').trim();
        setCardNo(formattedNumber);
        setCardNoErr('');
      };

    const handleCvcChange = (e) => {
        const inputNumber = e.target.value.replace(/\D/g, '');
        const formattedNumber = inputNumber.replace(/(\d{4})/g, '$1 ').trim();
        setCvc(formattedNumber);
        setCvcErr('');
      };






    return (
        <div className="payment-gateway">

                    <Modal
                        dialogClassName="h_auto"
                        show={successModel}
                        animation={false}
                        centered>
                        <Modal.Body>
                            <div className="p-5">
                                <h3 className="text-center mb-4">Success</h3>
                                Registration Successful
                                <Link className="btn btn-primary btn-block mt-2" to="/registrationsuccess">Ok</Link>
                            </div>
                        </Modal.Body>
                    </Modal>


            {
                props.grandtotal > 0 ? (
                    <Fragment>
                        <h3>Select a Payment Option</h3>

                        <div className="row">

                            {
                                creditCardRate.status == 1 ? (
                                    <div className="col-md-4">
                                        <div
                                            className={`payment-gateway-card ${paymentMethod == 1 ? 'active' : ''}`}
                                            onClick={() => (setPaymentMethod(1), setPaymentMethodErr(''))}>

                                            <h4>Credit Card / Debit Card</h4>
                                            <h2>${getWithTaxPrice(creditCardRate, props?.grandtotal)}</h2>
                                            {props.insuranceTotal > 0 && <p>Insurance Charges : ${getInsuranceWithTaxPrice(creditCardRate,props.insuranceTotal)}</p>}
                                            <p>Payment Includes {creditCardRate.percentage}% + {`$${creditCardRate.taxRate}`} Processing Charge</p>
                                        </div>
                                    </div>
                                ) : ''
                            }


                            {
                                achTransferRate.status == 1 ? (
                                    <div className="col-md-4">
                                        <div
                                            className={`payment-gateway-card ${paymentMethod == 3 ? 'active' : ''}`}
                                            onClick={() => (setPaymentMethod(3), setPaymentMethodErr(''))}>

                                            <h4>Internet Banking</h4>
                                            <h2>${getWithTaxPrice(achTransferRate, props?.grandtotal)}</h2>
                                            {props.insuranceTotal > 0 && <p>Insurance Charges : ${getInsuranceWithTaxPrice(achTransferRate,props.insuranceTotal)}</p>}
                                            <p>Payment Includes {achTransferRate.percentage}% + {`$${achTransferRate.taxRate}`} Processing Charge</p>
                                        </div>
                                    </div>
                                ) : ''
                            }

                            

                            {
                                byCheque.status == 1 ? (
                                    <div className="col-md-4">
                                        <div
                                            className={`payment-gateway-card ${paymentMethod == 4 ? 'active' : ''}`}
                                            onClick={() => (setPaymentMethod(4), setPaymentMethodErr(''))}>
                                            <h4>Check</h4>
                                            <h2>${getWithTaxPrice(byCheque, props?.grandtotal)}</h2>
                                            {props.insuranceTotal > 0 && <p>Insurance Charges : ${getInsuranceWithTaxPrice(byCheque,props.insuranceTotal)}</p>}
                                            <p>Payment Includes {`$${byCheque.taxRate}`} Processing Charge</p>

                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={<Tooltip>Payment will be made via mail or in person. Insurance coverages are not available if paying by check</Tooltip>}>
                                                    <span className='my_tooltip'><InformationIcon /></span>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                ) : ''
                            }



                            {
                                hasSplitOption(programStartDate, splitPaymentRate.noOfTimesPayment) && splitPaymentRate.status == 1 && splitPaymentRate.noOfTimesPayment > 1 ? (
                                    <div className="col-md-4">
                                       <div
                                        className={`payment-gateway-card ${paymentMethod == 5 ? 'active' : ''}`}
                                        onClick={() => (setPaymentMethod(5), setPaymentMethodErr(''))}>

                                            <h4>Split Payment Credit Card</h4>
                                            <h2>${getSplitPrice(splitPaymentRate, props?.grandtotal + props.insuranceTotal)}</h2>
                                            <p><b>{splitPaymentRate.noOfTimesPayment}</b> Payments</p>
                                            {props.insuranceTotal > 0 && <p>Insurance Charges : ${getInsuranceWithTaxPrice(splitPaymentRate,props.insuranceTotal)}</p>}
                                            <p>Payment Includes {splitPaymentRate.percentage}% + {`$${splitPaymentRate.taxRate}`} Processing Charge</p>
                                            
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={<Tooltip>Card automatically charged 30 days later for subsequent payments</Tooltip>}>
                                                    <span className='my_tooltip'><InformationIcon /></span>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                ) : ''
                            }




                            {/* {
                                splitPaymentRate.status == 1 && splitPaymentRate.noOfTimesPayment > 1 ? (
                                    <div className="col-md-4">
                                       <div
                                        className={`payment-gateway-card ${paymentMethod == 5 ? 'active' : ''}`}
                                        onClick={() => (setPaymentMethod(5), setPaymentMethodErr(''))}>

                                            <h4>Split Payment Credit Card</h4>
                                            <h2>${getSplitPrice(splitPaymentRate, props?.grandtotal + props.insuranceTotal)}</h2>
                                            <p><b>{splitPaymentRate.noOfTimesPayment}</b> Payments</p>
                                            <p>Payment Includes {splitPaymentRate.percentage}% + {`$${splitPaymentRate.taxRate}`} Processing Charge</p>
                                            
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={<Tooltip>Card automatically charged 30 days later for subsequent payments</Tooltip>}>
                                                    <span className='my_tooltip'><InformationIcon /></span>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                ) : ''
                            } */}

        
                        </div>


                        {paymentMethodErr ? <p className="color_red">{paymentMethodErr}</p> : ''}

                        {
                            (paymentMethod == 1 || paymentMethod == 5) ? (
                                <Fragment>
                                    <ul className="card_list">
                                        {cardList.length ? cardList.map(item => (
                                                <li key={item.signature} className={customerPaymentProfileId === item.card.token ? 'active' : ''}>
                                                <div className="check_box_wrapper cm_dark_check pt-1">
                                                    <input
                                                        checked={customerPaymentProfileId === item.card.token}
                                                        onChange={() => (setCustomerPaymentProfileId(item.card.token), setCustomerPaymentProfileIdError(''))}
                                                        type="radio"
                                                        name="gender"
                                                        value="male" />
                                                    <span className="check_box"><span></span></span>
                                                </div>
                                                <div className="p_card_img ml-4">
                                                    <img src={item?.card?.brand ? require(`../../assets/img/${getCardTypeName(item.card.brand)}.svg`) : require(`../../assets/img/Visa.svg`)} />
                                                </div>
                                                <p>********{item.card.acct_last_four}</p>
                                            </li>

                                            )) : ''
                                        }
                                    </ul>

                                    {customerPaymentProfileIdError ? <p className="color_red text-center">{customerPaymentProfileIdError}</p> : ''}


                                {
                                    isNewCard ? (
                                        <div className="card_inputs add_card_form">
                                            <div className="form-group">
                                                <label>Card Number</label>
                                                {/* <InputMask
                                                    className={`form-control ${cardNoErr ? 'is-invalid' : ''}`}
                                                    name="cardNumber"
                                                    placeholder="XXXX XXXX XXXX XXXX"
                                                    mask="9999 9999 9999 9999"
                                                    onKeyPress={() => setCardNoErr('')}
                                                    onChange={e => setCardNo(e.target.value)} /> */}

                                                    <input
                                                        type="text"
                                                        className={`form-control ${cardNoErr ? 'is-invalid' : ''}`}
                                                        name="cardNumber"
                                                        placeholder="XXXX XXXX XXXX XXXX"
                                                        value={cardNo}
                                                        maxLength={19}
                                                        onChange={handleCardNumberChange} />

                                                    {cardNoErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{cardNoErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                            </div>

                                            <div className="row">
                                                <div className="col form-group">
                                                    <label>Expiry Date</label>
                                                    <DatePicker
                                                        className={`form-control ${expDateErr ? 'is-invalid' : ''}`}
                                                        format="MM/YY"
                                                        picker="month"
                                                        placeholder="MM/YY"
                                                        onKeyPress={() => setExpDateErr('')}
                                                        onChange={e => (setMonth(`${addZero(e._d.getMonth() + 1)}`), setYear(`${e._d.getFullYear().toString()}`), setExpDate(`${addZero(e._d.getMonth() + 1)}${e._d.getFullYear().toString()}`), setExpDateErr(''))} />

                                                    {expDateErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{expDateErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                                </div>
                                                <div className="col form-group">
                                                    <label>CVC</label>
                                                    {/* <InputMask
                                                        className={`form-control ${cvcErr ? 'is-invalid' : ''}`}
                                                        placeholder="XXX"
                                                        mask="999"
                                                        onKeyPress={() => setCvcErr('')}
                                                        onChange={e => setCvc(e.target.value)} /> */}

                                                    <input
                                                        type="text"
                                                        className={`form-control ${cvcErr ? 'is-invalid' : ''}`}
                                                        name="cvc"
                                                        placeholder="XXX"
                                                        value={cvc}
                                                        maxLength={4}
                                                        onChange={handleCvcChange} />

                                                        {cvcErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{cvcErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label>Cardholder First Name</label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${firstNameErr ? 'is-invalid' : ''}`}
                                                    placeholder="Cardholder First Name"
                                                    onKeyPress={() => setFirstNameErr('')}
                                                    onChange={e => setFirstName(e.target.value)} />

                                                    {firstNameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{firstNameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                            </div>

                                            <div className="form-group">
                                                <label>Cardholder Last Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Cardholder Last Name"
                                                    onChange={e => setLastName(e.target.value)} />
                                            </div>

                                            <div className="form-group">
                                                <label>Zip Code</label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${zipCodeErr ? 'is-invalid' : ''}`}
                                                    placeholder="Zip Code"
                                                    onChange={e => (setZipCode(e.target.value), setZipCodeErr(''))} />

                                                    {zipCodeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{zipCodeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                            </div>

                                            {resErr ? <h6 className="res_error">{resErr}</h6> : ''}

                                            <div className="d-flex justify-content-between">
                                            <span onClick={() => setIsNewCard(false)} className="btn btn-info rounded-0 w-100 mr-2">Cancel</span>
                                            <span onClick={addCardBtnLoader ? null : handleAddCardSubmint} className={`btn btn-primary rounded-0 w-100  ${addCardBtnLoader ? 'btnLoader' : ''}`}>Add</span>
                                            </div>


                                            </div>
                                    ) : <button className="btn btn-primary mb-3" onClick={() => setIsNewCard(true)}>Add New Card</button>
                                } 
                            </Fragment>
                            ) : ''
                        }


                        {
                            paymentMethod == 2 ? <div><PaypalExpressBtn
                                client={client}
                                currency={'USD'}
                                total={props.grandtotal.toFixed(2)}
                                env="production"
                                onError={onError}
                                onSuccess={onSuccess}
                                onCancel={onCancel} />
                            </div> : ''
                        }


                        {    
                            paymentMethod == 3 ? (
                                <Fragment>
                                    <div className="card_inputs add_card_form">

                                        <div className="form-group">
                                            <label>Select Account Type</label>
                                            <Select
                                                className={`form-control ${bankAccountTypeErr ? 'is-invalid' : ''}`}
                                                placeholder="Select Account Type"
                                                onChange={e => (setBankAccountType(e), setBankAccountTypeErr(''))}>

                                                <Option value="checking">Checking</Option>
                                                <Option value="savings">Savings</Option>
                                                <Option value="businessChecking">business Checking</Option>

                                            </Select>

                                            {bankAccountTypeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{bankAccountTypeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                        </div>


                                        <div className="form-group">
                                            <label>Account Number</label>
                                            <input
                                                type='number'
                                                name="bankAccountNum"
                                                className={`form-control ${bankAccountNumErr ? 'is-invalid' : ''}`}
                                                value={bankAccountNum}
                                                placeholder="Account Number"
                                                onChange={e => (setBankAccountNum(e.target.value), setBankAccountNumErr(''))} />

                                            {bankAccountNumErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{bankAccountNumErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}

                                        </div>



                                        <div className="form-group">
                                            <label>Routing Number</label>
                                            <input
                                                type='number'
                                                className={`form-control ${routingNumberErr ? 'is-invalid' : ''}`}
                                                value={routingNumber}
                                                placeholder="Routing Number"
                                                name="routingNumber"
                                                onChange={e => (setRoutingNumber(e.target.value), setRoutingNumberErr(''))} />

                                            {routingNumberErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{routingNumberErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}

                                        </div>


                                        <div className="form-group">
                                            <label>Account Holder Name</label>
                                            <input
                                                type="text"
                                                className={`form-control ${nameOnAccountErr ? 'is-invalid' : ''}`}
                                                name="nameOnAccount"
                                                value={nameOnAccount}
                                                onChange={e => (setNameOnAccount(e.target.value), setNameOnAccountErr(''))}
                                                placeholder="Account Holder Name" />

                                            {nameOnAccountErr? <OverlayTrigger placement="left" overlay={<Tooltip>{nameOnAccountErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                        </div>




                                        </div>


                                        
                            </Fragment>
                            ) : ''
                        }
                    </Fragment>
                ) : ''
            }


            

            <div className="text-center mb-4">
                {errorMsg ? <p className="color_red">{errorMsg}</p> : ''}
                {allErrorMsg ? <p className="color_red">{allErrorMsg}</p> : ''}
                {props.slotAvailableMsg ? <p className="color_red">{props.slotAvailableMsg}</p> : ''}
                {props.isAlreadyBooked ? <p className="color_red">Already booked for this Participant</p> : ''}
                {props.isSlotAvailable && !props.isAlreadyBooked ? <button onClick={submitRegistration} disabled={btnloder} className={`btn btn-primary  ${btnloder ? 'btnLoader' : ''}`} >Submit Registration</button> : ''}
                
            </div>

        </div>
    )
}


const mapStateToProps = state => {
    let { loggedIn, userProfile } = state.user;
    let { isNewCardAdd } = state.ui;
    let itemsincart = state.cartReducer.cartitems;
    return {
        loggedIn,
        isNewCardAdd,
        itemsincart,
        userProfile
    }
}

const mapDispatchToProps = dispatch => ({
    getitemsincart: params => dispatch(getitemsincart(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentGateway);

