import React from "react";
import InsuranceComponent from "..";
import RegistarationComponent from "./RegistarationComponent";

const RegistrationCancellation = ({ updateInsuranceQuotes, allPrograms, camperList, insuranceState, userEmail, userName, userLastName, billingDetail }) => {
    return (
        <InsuranceComponent
            client-id="live_OS3ZRXTLX720CFG3PYVR9NW7AC4RODA9"
            offerStateChange={updateInsuranceQuotes.bind(this)}
            product="registration-cancellation"
            style={{ width: '100%' }}
        >
            {allPrograms.map((items, cartIndex) => {
                const eventDates = items.additionalDates?.filter(el => items.selectedDates.find((id) => el._id === id))?.flatMap(d => [d.startDate, d.endDate])?.sort(function (a, b) { return a - b; })
                let startDate;
                let endDate;
                const cartItem = items;
                const campers = cartItem.campers;
                if (eventDates && eventDates.length > 0) {
                    startDate = new Date(parseInt(eventDates[0])).toISOString().substring(0, 10);
                    endDate = new Date(parseInt(eventDates[eventDates.length - 1])).toISOString().substring(0, 10);
                } else {
                    startDate = new Date(items.startDate).toISOString().substring(0, 10);
                    endDate = new Date(items.endDate).toISOString().substring(0, 10);
                }
                return (
                    campers.map(camper => {
                        const camperDetails = camperList.find(c => c._id === camper.camperId);
                        return (
                            <RegistarationComponent
                                key={`out-gap-medical-insurance-offer-${cartItem._id}-${camper._id}`}
                                item={cartItem}
                                camper={camper}
                                insuranceState={insuranceState}
                                userEmail={userEmail}
                                userName={userName}
                                zipCode={camper?.zipCode}
                                userLastName={userLastName}
                                camperDetails={camperDetails}
                                startDate={startDate}
                                endDate={endDate}
                                camperId={camper._id}
                            />)
                    })
                );
            })}
        </InsuranceComponent>
    )
}

export default RegistrationCancellation
