import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

import setting from '../../config/settings';


  const Share = ({pageUrl}) => {
      const [url, setUrl] = useState('')

      const getLinks = () => {

        const rawResponse = fetch(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyCdDH9dkoLecpVX7gNRSkYMMV1eUfQ7VE0`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            "dynamicLinkInfo": {
              "domainUriPrefix": "https://campsez.page.link",
              "link": `${setting.api.SITE_URL}welcome?${pageUrl}`,
              "androidInfo": {
                "androidPackageName": "com.vic.campsez"
              },
              "iosInfo": {
                "iosBundleId": "com.mobcoder.CampsEZ"
              }
            }
          })
        }).then(content => content.json()).then(res => {
          setUrl(res.shortLink)
        })
      
        
      }


      return(
          <Dropdown drop="left" className="share_options" onClick={getLinks}>
            <Dropdown.Toggle><i className="icon-share-fill" /></Dropdown.Toggle>
            <Dropdown.Menu>
              <div className="share_wrapper">
                <Dropdown.Item href="javascript:void(0);"> 
                  <EmailShareButton url={url}><EmailIcon size="32" round={true} /></EmailShareButton>
                  <FacebookShareButton url={url}><FacebookIcon size="32" round={true} /></FacebookShareButton>
                  <LinkedinShareButton url={url}><LinkedinIcon size="32" round={true} /></LinkedinShareButton>
                  <TwitterShareButton url={url}><TwitterIcon size="32" round={true} /></TwitterShareButton>
                  <WhatsappShareButton url={url}><WhatsappIcon size="32" round={true} /></WhatsappShareButton>
                </Dropdown.Item>
              </div>
            </Dropdown.Menu>
          </Dropdown>
      )
  }

  export default Share;