import React from 'react';
import { connect } from 'react-redux';

import AuthModal from './AuthModal';
import OtpVerificationModal from './OtpVerificationModal';
import ForgotPassword from './ForgotPassword';
import ResetPasswordModal from './ResetPasswordModal';
import SuccessModal from './SuccessModal';
import FeedModal from './FeedModal';
import MapViewModal from './MapViewModal';
import PickupUserModal from './PickupUserModal';
import PasswordSuccessModal from './PasswordSuccessModal';
import ActivityModal from './ActivityModal';
import LoginAlertModal from './LoginAlertModal';
import AddFolder from './AddFolder';
import AddProgramModal from './AddProgramModal';
import ViewProgramDatesModal from './ViewProgramDatesModal';
import DirectorSignup from './DirectorSignup';

const Modals = props => (
    <React.Fragment>
        <AuthModal />
        {/* {props.isDirecterAuth ? <DirectorSignup /> : <AuthModal />} */}
        
        {props.otpModal ? <OtpVerificationModal /> : ''}
        <ForgotPassword />
        {props.resetPasswordModal ? <ResetPasswordModal /> : ''}
        <SuccessModal />
        <PasswordSuccessModal />
        {props.mapViewModal ? <MapViewModal /> : ''}
        {props.porgramDates ? <ViewProgramDatesModal /> : ''}
        <FeedModal />
        <PickupUserModal />
        <ActivityModal />
        <LoginAlertModal />
        <AddFolder />
        {props.dProgramModal ? <AddProgramModal /> : ''}
        
    </React.Fragment>
)


const mapStateToProps = state => {
    let { otpModal, mapViewModal, resetPasswordModal, porgramDates, isDirecterAuth, dProgramModal } = state.ui;
    return {
        otpModal,
        resetPasswordModal,
        mapViewModal,
        porgramDates,
        isDirecterAuth,
        dProgramModal
    };
}
  
  
export default connect(mapStateToProps)(Modals);