
import React, { useState, useEffect, useReducer, useRef, Fragment } from 'react';
import {  Modal } from 'react-bootstrap';
import { getitemsincart } from '../../store/cart'
import { connect } from 'react-redux';

import { ProgramLoader } from '../reuse/SVG';
import {
    confirmedlist,
    validatecoupon,
    validategiftcard,
    confirmedlistNonLoginUser,
    validateSlotsService,
    validateBookingService
} from '../../services/programService';
import { billingDetailService } from '../../services/otherService';
import { dateFormatpaymentconfirm, getprogramdatesinCart, provinceList } from '../../helper';
import PaymentGateway from '../payment/PaymentGateway';
import _ from "lodash";
import moment from 'moment';
import { getCampDetailService } from '../../services/userServices';
import axios from "axios";
import api from "../../config/api";

const InsuranceComponent = (props) => {
    const {children, ...otherProps} = props;

    const ref = useRef();
    useEffect(() => {
        if (ref && ref.current) {
            const currentRef = ref.current;
            if (props.offerStateChange) {
                currentRef.addEventListener("offer-state-change", props.offerStateChange);
                return function cleanup() {
                    currentRef.removeEventListener("offer-state-change", props.offerStateChange);
                };
            }
        }
    }, [props.offerStateChange]);

    return (
        // <InsuranceComponentMemo {...props} ref={ref}/>
        <multi-offer {...otherProps} ref={ref}>
            {children}
        </multi-offer>
    )
}

function GapMedicalInsuranceComponent(props) {
    const {campCode, programId, userId, ...otherProps} = props;
    const [campDetails, setCampDetails] = useState();
    const ref = useRef();
  
    const getProgramDetails = async () => {
      let query = `?programId=${programId}`; 
      const accessToken = localStorage.getItem('accessToken');
  
      const res = await axios.get(api.VIEW_PROGRAM + query, {
        headers: {
          'accessToken': accessToken,
          'Authorization': api.AUTH
        }
      });
  
      if(res?.data?.statusCode 
          && (res.data.responseData.result.categoryName === 'Dance' || res.data.responseData.result.categoryName === 'Sports')
          && res.data.responseData.result.subCategory.length === 1) {
              
        setCampDetails(res.data.responseData.result);
      }
    }
  
    useEffect(() => {
        getProgramDetails();
    }, []);
  
    return campDetails ? (
        <gap-medical {...otherProps} coverage-type={campDetails.categoryName === 'Dance' ? 'DANCE' : campDetails.subCategory[0].name} ref={ref}></gap-medical>
    ) : null;
  }

 const PaymentConfirmation = props => {
     const [cartItems, setCartItems] = useState([]);
     const [loader, setloader] = useState(true);
     const [cartIdsarr, setCartIds] = useState([]);
     const [campssubtotal, setCampsSubtotal] = useState();
     const [grandtotal, setGrandTotal] = useState();
     const [programids, setProgramIds] = useState([]);
     const [programIDwithPr, setProgramIDwithPr] = useState([]);
     const [inValidGift, setInvalidGift] = useState(false);
     const [btnlodergit, setBtnLoaderGift] = useState(false);
     const [giftCardSuccess, setGiftCardSuccess] = useState(false);
     const [giftCartValue, setGiftCardValue] = useState();
     const [giftCartName, setGiftCardName] = useState();
     const [inValidCopuon, setInvalidCopuon] = useState(false);
     const [btnlodercopuon, setBtnLoaderCopuon] = useState(false);
     const [copuonSuccess, setCopuonSuccess] = useState(false);
     const [copuonValue, setCopuonValue] = useState();
     const [copuonName, setCopuonName] = useState();
     const copuoncodefiled = useRef();
     const giftcodefiled = useRef();
     const [showCancelPolicy, setShowCancelPolicy] = useState(false);
     const [cancellationAvailable, setCancellationAvailable] = useState('');
     const [cancellationType, setCancellationType] = useState('');
     const [cancellationValue, setCancellationValue] = useState('');
     const [refundAvailable, setRefundAvailable] = useState('');
     const [refundType, setRefundType] = useState('');
     const [refundOptions, setRefundOptions] = useState([]);
     const [campName, setCampName] = useState([]);
     const [is_payment_split, setIs_payment_split] = useState('');
     const [allCampCodes, setAllCampCodes] = useState('');
     const [billingDetail, setBillingDetail] = useState({});
     const [insuranceState, setInsuranceState] = useState('');
     const [isInsuranceStateSelected, setIsInsuranceStateSelected] = useState(false);
     const [insuranceRender, setInsuranceRender] = useState(true);

     const [acceptedInsuranceQuotes, setAcceptedInsuranceQuotes] = useState();
     const [insuranceTotal, setInsuranceTotal] = useState(0);
     const [validateSlots, setValidateSlots] = useState([]);
     const [isSlotAvailable, setIsSlotAvailable] = useState(false);
     const [slotAvailableMsg, setSlotAvailableMsg] = useState('');
     const [discountArr, setDiscountArr] = useState([]);
     const [isAlreadyBooked, setIsAlreadyBooked] = useState(false);
     const [totalProductsPrice, setTotalProductsPrice] = useState(0);
     const [showInsurance, setShowInsurance] = useState(false)


     useEffect(() => {
        if(!insuranceRender){
            setTimeout(() => {
                setInsuranceRender(true);
            }, 100)
        }
    }, [insuranceRender])


     const calcAmount = (_data) => {
        let cartitemsDetails = _data;
        let _allCampCodes = Array.from(new Set(cartitemsDetails.map(_codes => _codes.campCode)));
        setAllCampCodes(_allCampCodes);
        let cartitemspre = [];
        let precartIds = [];
        let programiDs = [];
        let _programIDwithPr = [];
        let _additionalExperience = [];
        let campstotalprice = 0;
        let additionalDiscount = 0;
        let checkSlots = [];
        let _totalProductsPrice = 0;
        cartitemsDetails.map((item, i, allArray) => {
            checkSlots.push({
                programId: item.programId,
                selectedDates: item.selectedDates,
                noOfBookings: item.numberOfBooking        
            })  

            if (item.detailConfirmed == 1) {
                let myCount = allArray.filter(pro => pro.campCode == item.campCode)
                programiDs.push(item.programId);
                precartIds.push(item._id);
                

                if(item?.associatedCartInfo?.length){
                    let total_product_price = 0;
                    for (let i = 0; i < item?.associatedCartInfo.length; i++) {
                        total_product_price = total_product_price + (item?.associatedCartInfo[i].price * item?.associatedCartInfo[i].count);
                        let _subItemPrice  = 0;
                        if(item?.associatedCartInfo[i]?.subItems?.length){
                         _subItemPrice = item?.associatedCartInfo[i]?.subItems.reduce((sum, p) => (sum + (p.count*p.price)), 0)
                        }
                        total_product_price +=  _subItemPrice;
                    }
                    _totalProductsPrice = _totalProductsPrice + total_product_price;
                    campstotalprice = campstotalprice + total_product_price;
                }
                

                if (item.campers !== undefined) {
                    item.campers.map(camper => {

                        if (camper?.additionalExperience?.length) {
                            _additionalExperience = camper?.additionalExperience.filter(jj => jj.numberOfBooking > 0);
                        }


                        //Note => if the option matches the answer then discount is applied
                        additionalDiscount=0 // Reset additionalDiscount. 
                        if (camper.answers !== undefined) {
                            camper.answers.map(answ => {
                                let _discountAmount = 0
                                if (answ.options !== undefined) {
                                    answ.options.map(optio => {
                                        if(optio.opt == answ.answer){
                                            if(optio.discountType && optio.discount){
                                                _discountAmount = (optio.discountType == 1 ? optio.discount : ((item.registrationFee / 100) * optio.discount))
                                            }
                                        }
                                    })
                                }
                                additionalDiscount += _discountAmount;
                            })
                        }
                    }) 
                }
                cartitemspre.push({...item, myCount: myCount.length, additionalDiscount });
            }

            _programIDwithPr.push({
                programId: item.programId,
                programCost: item.f_campRegistrationFee + item.f_taxPrice,
                additionalExperience: _additionalExperience || []
            })

            campstotalprice = campstotalprice + (item.f_totalPrice - additionalDiscount)

        });

        setTotalProductsPrice(_totalProductsPrice);
        setValidateSlots(checkSlots);
        setProgramIds(programiDs);
        setProgramIDwithPr(_programIDwithPr)
        setCartItems(cartitemspre);
        setCartIds(precartIds);
        setloader(false);
        setCampsSubtotal(campstotalprice);
        setGrandTotal(campstotalprice);
     }

    useEffect(() => {
        let confirmedlistApi = '';
        if (props.loggedIn){
            confirmedlistApi = confirmedlist()
        }else{
            confirmedlistApi = confirmedlistNonLoginUser()
        }
        confirmedlistApi.then((res) => {
            if (res.data.statusCode == 0) {
                setCartItems([]);
            } else {
                calcAmount(res.data.responseData.result)
            }
        });

        billingDetailService().then(res => {
            if (res.data.statusCode) {
                setBillingDetail(res.data.responseData.billingDetail);
                setInsuranceState(res.data.responseData.billingDetail.state);

                if(res.data.responseData.billingDetail.state.length == 2){
                    setIsInsuranceStateSelected(false)
                }

            }
        })
    }, [props.loggedIn])



    useEffect(() => {
        if(validateSlots.length){
            validateSlotsService({programData: JSON.stringify(validateSlots)}).then(res => {
                if(res.data.statusCode){
                    if(res.data.responseData.result == 1){
                        setIsSlotAvailable(true)
                    }else{
                        setIsSlotAvailable(false);
                        setSlotAvailableMsg(res.data.responseData.message)
                    }

                }
            })
        }
    }, [validateSlots])


    useEffect(() => {
        if(cartItems.length){
            let cartIds  = cartItems.map(i => i._id);
            let params = {
                cartIds,
            }

            validateBookingService(params).then(res => {
                if(res.data.statusCode == 0){
                    if(res.data.error.errorCode == 55){
                        setIsAlreadyBooked(true);
                    }

                }
            })
        }
    }, [cartItems])


    const handelCopuonCode = () => {
         setBtnLoaderCopuon(true);
         let copuncodevalue = copuoncodefiled.current.value;
         if (copuncodevalue == '') {
             setBtnLoaderCopuon(false);
         } else {

            let parms = {
                couponCode: copuncodevalue,
                programId: JSON.stringify(programids),
                programData: JSON.stringify(programIDwithPr)
             }

             validatecoupon(parms).then(res => {
                 if (res?.data?.responseData?.code == 1) {
                     let copuoncodePrice = res.data.responseData.discountMoney;
                     let _discountArr = res.data.responseData.discountArr || [];
                     let grandtot = campssubtotal - copuoncodePrice;
                     if (copuoncodePrice > campssubtotal) {
                         grandtot = 0;
                     } else {
                         if (giftCartValue) {
                             grandtot = grandtot - giftCartValue;
                         }
                     }
                     setBtnLoaderCopuon(false);
                     setCopuonSuccess(true);
                     setCopuonValue(`${copuoncodePrice}`);
                     setDiscountArr(_discountArr);
                     setCopuonName(copuncodevalue);
                     setGrandTotal(grandtot);
                     setInvalidCopuon(false);
                 } else {
                     let grandtot = campssubtotal;
                     if (giftCartValue) {
                         grandtot = grandtot - giftCartValue;
                     }
                     setBtnLoaderCopuon(false);
                     setInvalidCopuon(true);
                     setCopuonSuccess(false);
                     setCopuonValue();
                     setCopuonName();
                     setGrandTotal(grandtot);
                 }
             })
         }
     }


    const handelGiftCartCode = () => {
         setBtnLoaderGift(true);
         let giftCardvalue = giftcodefiled.current.value;
         if (giftCardvalue == '') {
             setBtnLoaderGift(false);
         }
        else {
            let parms = `/?giftCode=${giftCardvalue}`;
            validategiftcard(parms).then((res) => {
                if (res.data.responseData.code == 1) {
                    let giftcardPrice = res.data.responseData.result;
                    let grandtot = campssubtotal - giftcardPrice;
                    if (giftcardPrice > campssubtotal) {
                        grandtot = 0;
                    } else {
                        if (copuonValue) {
                            grandtot = grandtot - copuonValue;
                        }
                    }
                    setBtnLoaderGift(false);
                    setGiftCardSuccess(true);
                    setGiftCardValue(res.data.responseData.result)
                    setGiftCardName(giftCardvalue);
                    setGrandTotal(grandtot);
                    setInvalidGift(false);
                }
                else {
                    let grandtot = campssubtotal;
                    if (copuonValue) {
                        grandtot = grandtot - copuonValue;
                    }
                    setGrandTotal(grandtot);
                    setBtnLoaderGift(false);
                    setInvalidGift(true);
                    setGiftCardValue()
                    setGiftCardName();
                    setGiftCardSuccess(false);
                }
            })
        }
     }



    const hendleCancelPolicy = data => {
         setShowCancelPolicy(true);
         setCancellationAvailable(data.cancellationAvailable)
        setCancellationType(data.cancellationType)
        setCancellationValue(data.cancellationValue)
        setRefundAvailable(data.refundAvailable)
        setRefundType(data.refundType)
        setRefundOptions(data.refundOptions)
        setCampName(data.campName)
     }

    const handleCloseModal = () => {
         setShowCancelPolicy(false)
        setCancellationAvailable('')
        setCancellationType('')
        setCancellationValue('')
        setRefundAvailable('')
        setRefundType('')
        setRefundOptions([])
        setCampName('')
     }


    const getSelectedDates = (_all, _sel) => {
         const _res = _all.filter(el => _sel.find((id) => el._id == id));
         return _res.map(i => (
             <span className='cm_selected_date' key={i._id}>
                 {getprogramdatesinCart(i.startDate,i.endDate)}
             </span>
             ));
     }

    const validateInsuranceOffers = async () => {
        const offers = document.querySelectorAll('multi-offer');
        let allValid = true; 
        
        for (const offer of offers) {
            if (offer.quotes && offer.quotes.length > 0) {
                const res = await offer.validate();
                if (!res.isValid) allValid = false;
            }
        }

        return allValid;
    }

    const updateInsuranceQuotes = () => {
        const offers = document.querySelectorAll('multi-offer');
        const acceptedInsuranceOffers = [];
        let insuranceTotal = 0;

        offers.forEach(offer => {
            if (offer.quotes && offer.quotes.length > 0) {
                offer.style.display = 'block';
                if (offer.selectedState !== 1) {
                    offer.quotes.forEach(quoteDetails => {
                        if (quoteDetails.selectedState === 'ACCEPTED') {
                            acceptedInsuranceOffers.push({quote: quoteDetails.quote, type: offer.product, eventName: `${quoteDetails.quote.metadata.context_name} - ${quoteDetails.quote.metadata.context_event}`});
                            insuranceTotal += (quoteDetails.quote.total / 100);
                        }
                    });
                }
            } else {
                offer.style.display = 'none';
            }
            
        });

        setAcceptedInsuranceQuotes(acceptedInsuranceOffers);
        setInsuranceTotal(insuranceTotal);
     }

     const getTotalCampCost = (items) => {
        const extraCost = items.associatedCartInfo ? items.associatedCartInfo.reduce( (sum, item , i)=> {
           let _totalSum =  sum + (item.price* item.count);
           let _subItemPrice = 0;
            if(item?.subItems?.length){
            _subItemPrice = item.subItems.reduce((sum, p) => (sum + (p.count*p.price)), 0)
            }
            return _totalSum + _subItemPrice;
        }, 0) : 0;
        return Number(items.f_totalPrice.toFixed(2)) + Number(extraCost.toFixed(2));/////
    }
    const getShowInsurance = () => {
        let query = '';
        if(props?.cartItems?.length == 1){
            query = `?campCode=${allCampCodes}&programId=${props.cartItems[0].programId}`;
        }else{
            query = `?campCode=${allCampCodes}`;
        }
        getCampDetailService(query).then(res => {
            if(res?.data?.statusCode){
              let _data = res.data.responseData.result;
                setShowInsurance(_data?.showInsurance ? _data.showInsurance : false);
            }
        })
    }

    useEffect(() => {
        if(allCampCodes.length){
            getShowInsurance();
        }
      
    }, [allCampCodes])

    return (
        <div className="body_container">
            {
                loader ? <div className="program_loader"><ProgramLoader /></div> :
                    <div className="container">
                        <Modal
                            dialogClassName="h_auto"
                            show={showCancelPolicy}
                            animation={false}
                            size="lg"
                            centered>
                            <Modal.Body>
                                <div className="p-5 cancel_policy">
                                    <span className="modal_close" onClick={handleCloseModal}><i className="icon-cross" /></span>

                                    <p>If a Non-Refundable Registration Fee is listed, the below terms are not applicable to this portion of the fees but are applicable to all other fees.  The 24hr refund policy applies to all fees and all purchases, at all times.<br />Cancellation terms vary by program please click the "yellow" button next to other programs to check their terms as well, if you are purchasing multiple programs provided by different providers.</p>

                                    {
                                        refundAvailable == 1 ? (
                                            <Fragment>
                                                <h6>{refundType == 2 ? 'Original Form of Payment' : 'Program Voucher'}</h6>
                                                <ul>
                                                    {refundOptions.map(policy => <li>{`For ${policy.days}% refund, booking must be cancelled ${policy.percent} days prior to program start date.`}</li>)}
                                                </ul>
                                            </Fragment>
                                            ) : <h6>No Refund Available</h6>
                                    }

                                    <hr />

                                    <h5 className="mb-4">Cancellation by <span className="color_red">{campName}</span></h5>
                                    {
                                        cancellationAvailable == 1 ? (
                                            <Fragment>
                                                <h6>{cancellationType == 2 ? 'Original Form of Payment' : 'Program Voucher'}</h6>
                                                <ul>
                                                    <li>{`Refund amount will be ${cancellationValue}%  In the case of a cancellation prompted by low enrollment or other extenuating circumstances.`}</li>
                                                </ul>
                                            </Fragment>
                                            ) : <h6>No Refund Available</h6>
                                    }
                                </div>
                            </Modal.Body>
                        </Modal>


                        <div className="d-flex align-items-center justify-content-between section_header">
                            <h2>Payment Information</h2>
                        </div>
                        {
                            cartItems.map((items, cartIndex) => (
                                <div className="row" key={items._id}>
                                    <div className="col-xl-11">
                                        <div className="c_bor_b">

                                            <div className={`d-flex justify-content-${items.byDates == 1 ? 'center' : 'between'} date_heading`}>
                                                <span></span>
                                                <span>{items.campName} - {items.programName}</span>
                                                {items.byDates == 1 ? '' : <span>{moment.utc(items.startDate).format('MM/DD/YYYY')} to {moment.utc(items.endDate).format('MM/DD/YYYY')} </span>}
                                                {/* {items.byDates == 1 ? '' : <span>{dateFormatpaymentconfirm(items.startDate)} to {dateFormatpaymentconfirm(items.endDate)}</span>} */}
                                            </div>

                                            {
                                                items.byDates == 1 ? (
                                                    <div className="d-flex">
                                                        <p className='mr-3 mb-0'>Program Dates:</p>
                                                        <p className='mb-0'>{getSelectedDates(items.additionalDates, items.selectedDates)}</p> 
                                                    </div>
                                                ) : ''
                                            }


                                            <div className="d-flex justify-content-end">
                                                <p>Total Cost:</p>
                                                <b>${getTotalCampCost(items).toFixed(2)}</b>
                                                
                                            </div>

                                            {
                                                items.byDates != 1 ? <div className="d-flex justify-content-end">
                                                <p>Program Cost:</p><b>${(items.f_campRegistrationFee - (items.f_registerNonRefundFee || 0)).toFixed(2)}</b>
                                            </div> : <div className="d-flex justify-content-end">
                                                <p>Program Cost:</p><b>${items.f_campRegistrationFee.toFixed(2)}</b>
                                            </div>
                                            }

                                            

                                            {
                                            
                                                // items.byDates != 1 // show if non 0 
                                                // && 
                                                items.f_registerNonRefundFee 
                                                ? (
                                                    <div className="d-flex justify-content-end">
                                                        <p>Non Refundable Registration Amount: </p><b>${items.f_registerNonRefundFee.toFixed(2)}</b>
                                                    </div>
                                                ) : ''
                                            }

{
                                                items.f_additionalTotal ? (
                                                    <div className="d-flex justify-content-end">
                                                        <p>Additional Experience Total:</p><b>${items.f_additionalTotal.toFixed(2)}</b>
                                                    </div>
                                                ) : ''
                                            }

                                            <div className="d-flex justify-content-end">
                                                <p>Convenience Fee:</p><b>${items.f_convenienceFee.toFixed(2)}</b>
                                            </div>

                                            {
                                                items.f_taxPrice ? (
                                                    <div className="d-flex justify-content-end">
                                                        <p>Taxes:</p><b>${items.f_taxPrice.toFixed(2)}</b>
                                                    </div>
                                                ) : ''
                                            }

                                                
                                            {
                                                (items.f_discountPrice || items.additionalDiscount) ? (
                                                    <div className="d-flex justify-content-end">
                                                        <p>Additional Discount:</p><b>-${(items.f_discountPrice + items.additionalDiscount).toFixed(2)}</b>
                                                    </div>
                                                ) : ''
                                            }


                                            {
                                                items.f_siblingDiscount ? (
                                                    <div className="d-flex justify-content-end">
                                                            <p>Sibling Discount:</p><b>${items.f_siblingDiscount.toFixed(2)}</b>
                                                    </div>
                                                ) : ''
                                            }

                                            {
                                                items.f_multiProgramDiscount ? (
                                                    <div className="d-flex justify-content-end">
                                                        <p>Multi Programs Discount:</p><b>${items.f_multiProgramDiscount.toFixed(2)}</b>
                                                    </div>
                                                ) : ''
                                            }

                                            

                                            {
                                                items?.associatedCartInfo?.length ? <ul>
                                                    {
                                                        items?.associatedCartInfo.map(({title, price, count, itemId, subItems}) => (
                                                            <>
                                                            <div key={itemId} className="d-flex justify-content-end">
                                                                <p>{title} ( {count} × ${price} ):</p><b>${(count * price).toFixed(2)}</b>
                                                            </div>
                                                                <>
                                                                    {subItems?.length ?
                                                                        subItems?.map((si) => 
                                                                            <div key={itemId} className="d-flex justify-content-end"><br/><p>{si.title} ( {si.count} × ${si.price} ):</p><b>${(si.count * si.price).toFixed(2)}</b></div>
                                                                        ) : ''
                                                                    }
                                                                </>
                                                            </>
                                                        ))
                                                    }
                                                </ul> : ''
                                            }


                                            <div className="d-flex justify-content-between">
                                                <p><small>Payment for required items is due when the Program is purchased, and will be included in today’s total</small></p>
                                                <div><button className="btn btn-secondary btn-sm cp_btn" onClick={() => hendleCancelPolicy(items)}>Cancellation Policy</button> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            ))
                        }

                        <div class="row">
                            <div className="col-xl-11">
                                <div className="c_bor_b">

                                    {
                                        showInsurance && !isInsuranceStateSelected ? <div className='row mb-4 mt-4'>
                                            <div className='col-md-8'>
                                                <p>Please select state for insurance</p>
                                            </div>
                                            <div className='col-md-4'>
                                                <select
                                                    value={insuranceState}
                                                    onChange={e => (setInsuranceState(e.target.value), setInsuranceRender(false))}
                                                    className={`form-control`} >
                
                                                    <option value="">Select State/Province</option>   
                                                        {provinceList.map((item, index) => (
                                                                <option value={item.value} key={index}>
                                                                {item.value} - {item.label}
                                                                </option>
                                                            ))}
                                                </select> 
                                            </div>
                                        </div> : ''
                                    }

                                    {
                                        showInsurance && insuranceRender && insuranceState.length === 2 && props?.userProfile?.email && props?.userProfile?.lastName ? 
                                        <div style={{display: 'flex', flexDirection: 'column', gap: 10, alignItems: 'center'}}>
                                            <InsuranceComponent
                                                client-id="live_OS3ZRXTLX720CFG3PYVR9NW7AC4RODA9"
                                                offerStateChange={updateInsuranceQuotes.bind(this)}
                                                product="registration-cancellation"
                                                style={{width: '100%'}}
                                            >
                                                {cartItems.map((items, cartIndex) => {
                                                    const eventDates = items.additionalDates?.filter(el => items.selectedDates.find((id) => el._id === id))?.flatMap(d => [d.startDate, d.endDate])?.sort(function (a, b) {  return a - b;  })
                                                    let startDate;
                                                    let endDate;
                                                    if (eventDates && eventDates.length > 0) {
                                                        startDate = new Date(parseInt(eventDates[0])).toISOString().substring(0, 10);
                                                        endDate = new Date(parseInt(eventDates[eventDates.length - 1])).toISOString().substring(0, 10);
                                                    } else {
                                                        startDate = new Date(items.startDate).toISOString().substring(0, 10);
                                                        endDate = new Date(items.endDate).toISOString().substring(0, 10);
                                                    }
                                                   

                                                    return (
                                                        <registration-cancellation id={`reg-cancellation-insurance-offer-${items._id}`} key={`reg-cancellation-insurance-offer-${items._id}`}
                                                            customer-state={insuranceState}
                                                            customer-email-address={props.userProfile.email}
                                                            customer-first-name={props.userProfile.name}
                                                            customer-last-name={props.userProfile.lastName}
                                                            customer-city="NA"
                                                            customer-address="NA"
                                                            customer-postal-code={billingDetail?.zipCode}
                                                            data-unique-id={items._id}
                                                            data-context-name={items.campName}
                                                            data-context-event={items.programName}
                                                            event-start-date={startDate}
                                                            event-end-date={endDate}
                                                            insurable-amount={(items.f_totalPrice * 100).toFixed(0)}
                                                            coverage-context-description={`${items.campName} - ${items.programName}`}
                                                        ></registration-cancellation>
                                                    );
                                                })}
                                            </InsuranceComponent>
                                            <InsuranceComponent
                                                client-id="live_OS3ZRXTLX720CFG3PYVR9NW7AC4RODA9"
                                                offerStateChange={updateInsuranceQuotes.bind(this)}
                                                product="gap-medical"
                                                style={{display: 'none', width: '100%'}}
                                            >
                                                {cartItems.map((item, cartIndex) => {
                                                    const cartItem = item;
                                                    const campers = cartItem.campers;
                                                    const eventDates = cartItem.additionalDates?.filter(el => cartItem.selectedDates.find((id) => el._id === id))?.flatMap(d => [d.startDate, d.endDate])?.sort(function (a, b) {  return a - b;  })
                                                    let startDate;
                                                    let endDate;

                                                    if (eventDates && eventDates.length > 0) {
                                                        startDate = new Date(parseInt(eventDates[0])).toISOString().substring(0, 10);
                                                        endDate = new Date(parseInt(eventDates[eventDates.length - 1])).toISOString().substring(0, 10);
                                                    } else {
                                                        startDate = new Date(cartItem.startDate).toISOString().substring(0, 10);
                                                        endDate = new Date(cartItem.endDate).toISOString().substring(0, 10);
                                                    }

                                                    return campers.map(camper => {
                                                        const camperDetails = props.camperList.find(c => c._id === camper.camperId);
                                                        const insuranceItem = (
                                                            <GapMedicalInsuranceComponent key={`gap-medical-insurance-offer-${cartItem._id}-${camper._id}`}
                                                                customer-state={insuranceState}
                                                                customer-email-address={props.userProfile.email}
                                                                customer-first-name={props.userProfile.name}
                                                                customer-last-name={props.userProfile.lastName}
                                                                customer-city="NA"
                                                                customer-address="NA"
                                                                customer-postal-code={billingDetail?.zipCode}
                                                                data-context-name={cartItem.campName}
                                                                data-context-event={cartItem.programName}
                                                                coverage-start-date={startDate}
                                                                coverage-end-date={endDate}
                                                                participant-first-name={camperDetails.firstName}
                                                                participant-last-name={camperDetails.lastName}
                                                                participant-street-address="NA"
                                                                participant-state={insuranceState}
                                                                participant-birth-date={new Date(camperDetails.dob).toISOString().substring(0, 10)}
                                                                programId={cartItem.programId}
                                                                campCode={cartItem.campCode}
                                                                userId={props.userProfile._id}
                                                                coverage-context-description={`${cartItem.campName} - ${cartItem.programName}`}
                                                            />
                                                        );

                                                        return insuranceItem;
                                                    }) 
                                                } )}
                                            </InsuranceComponent>
                                        </div> : ''
                                    }
                                    
                                </div>
                            </div>
                        </div>

                        


                        <div className="row">
                            <div className="col-xl-11">
                                <div className="c_bor_b">

                                    <h4>Coupons & Rewards</h4>
                                    <p><small>If you have a Coupon, or Rewards Code enter it here to redeem.</small></p>
                                    <div className="d-flex justify-content-end align-items-center">
                                        <label className="m-0">Enter Code:</label>
                                        <div className="position-relative">
                                            <input onChange={() => { setInvalidCopuon(false) }} type="text" ref={copuoncodefiled} placeholder="Enter Coupon Code" className="form-control w-auto ml-2 mr-2" />
                                            {inValidCopuon && <span className="cm_err_msg2">Invalid Coupon Code</span>}
                                        </div>
                                        <button onClick={handelCopuonCode} className={`btn btn-primary pl-5 pr-5 ${btnlodercopuon ? 'btnLoader' : ''}`}>Apply</button>
                                    </div>

                                    {copuonSuccess && <p className="cm_success_msg">Coupon Applied Successfully</p>}

                                </div>
                            </div>
                        </div>



                        <div className="row">
                            <div className="col-xl-11">
                                <div className="c_bor_b">

                                    <h4>Gift Card</h4>
                                    <p><small>If you have a Gift Card  Code enter it here to redeem.</small></p>

                                    <div className="d-flex justify-content-end align-items-center">

                                        <label className="m-0">Enter Code:</label>
                                        <div className="position-relative">
                                            <input onChange={() => { setInvalidGift(false) }} ref={giftcodefiled} type="text" placeholder="Enter Gift Card Code" className="form-control w-auto ml-2 mr-2" />
                                            {inValidGift && <span className="cm_err_msg2">Invalid Gift Card</span>}
                                        </div>
                                        <button onClick={handelGiftCartCode} className={`btn btn-primary pl-5 pr-5 ${btnlodergit ? 'btnLoader' : ''}`}>Apply</button>
                                    </div>
                                    {giftCardSuccess && <p className="cm_success_msg">Gift Card Applied Successfully</p>}

                                </div>
                            </div>
                        </div>



                        <div className="row">
                            <div className="col-xl-11">
                                <div className="c_bor_b">
                                    <h4>Payment Method </h4>

                                    <div className="d-flex justify-content-end">
                                        <p>Subtotal:</p><b> {campssubtotal > 0 ? `$${campssubtotal.toFixed(2)}` : '$0'}</b>
                                    </div>

                                    <Fragment>
                                          {acceptedInsuranceQuotes && Object.values(acceptedInsuranceQuotes).map((q, i) => (
                                            <div key={i} className="d-flex justify-content-end">
                                                <p>{q.type === 'gap-medical' ? 'Gap Medical Insurance' : 'Registration Insurance'} ({q.eventName}):</p><b> ${(q.quote.total / 100).toFixed(2)}</b>
                                            </div>
                                          ))}
                                    </Fragment>

                                    {
                                        copuonValue ? (
                                            <div className="d-flex justify-content-end">
                                                <p>Coupon Applied:</p><b>{copuonValue ? `$${parseFloat(copuonValue).toFixed(2)}` : '$0'}</b>
                                            </div>
                                        ) : ''
                                    }

                                    {
                                        giftCartValue ? (
                                            <div className="d-flex justify-content-end">
                                                <p>Gift Card Applied:</p><b>${giftCartValue}</b>
                                            </div>
                                        ) : ''
                                    }
                                </div>

                                <PaymentGateway
                                    cartItems={cartItems}
                                    isAlreadyBooked={isAlreadyBooked}
                                    allCampCodes={allCampCodes}
                                    copuonObj={{copuonValue, copuonName, discountArr}}
                                    giftCartObj={{giftCartValue, giftCartName}}
                                    cartIdsarr={cartIdsarr}
                                    is_payment_split={is_payment_split}
                                    billingDetail={billingDetail}
                                    insuranceTotal={insuranceTotal}
                                    insuranceQuotes={acceptedInsuranceQuotes}
                                    insuranceOfferValidator={validateInsuranceOffers}
                                    grandtotal={grandtotal}
                                    totalProductsPrice={totalProductsPrice}
                                    isSlotAvailable={isSlotAvailable}
                                    slotAvailableMsg={slotAvailableMsg} />
                            </div>
                        </div>


                    </div>
            }
        </div>
    )
}


const mapStateToProps = state => {
    let { loggedIn, userProfile } = state.user;
    let { isNewCardAdd } = state.ui;
    let itemsincart = state.cartReducer.cartitems;
    let camperList = state.program.camperList;
    return {
        loggedIn,
        isNewCardAdd,
        itemsincart,
        userProfile,
        camperList
    }
}

const mapDispatchToProps = dispatch => ({
    getitemsincart: (cartitems) => dispatch(getitemsincart(cartitems))
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentConfirmation);