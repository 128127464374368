import React, {useState, useEffect, Fragment} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import Slider from 'react-slick';
import Select from 'react-select';

import { getCartItems, removeFromCart, confirmedlist, bookingCreate, validateSlotsService, getCartProductsService } from '../../services/programService';
import { ProgramLoader } from '../reuse/SVG';
import Cartitems from './cartItems';
import {getitemsincart, productCartCountAction} from '../../store/cart'
import { addProductIntoCartService, getProductsCartCountService, getProductsService } from '../../services/onlineStoreService';
import { getHtml, isNonEmptyArray } from '../../helper';
import ProductPaymentGateway from '../payment/ProductPaymentGateway';
import { notification } from 'antd';
import ConfirmDiloag from '../modals/ConfirmDiloag';



const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};



const LoginUserCart =props => {
const [cartItems, setCartItems]                     = useState([]);
const[loader, setloader]                            = useState(true);
const[isDetailsConfirmed, setDetailsConfirmed]      = useState(0);
const[isAllFree, setIsAllFree]                      = useState('');
const[cartIds, setCartIds]                          = useState([]);
const [btnloder, setBtnLoader]                      = useState(false);
const [successModel, SetsuccessModel]               = useState(false);
const [miniLoader, setMiniLoader]                   = useState({});
const [cartView, setCartView]                       = useState(1);
const [products, setProducts]                       = useState([]);
const [count, setCount]                             = useState(0);
const [productCheckoutModal, setProductCheckoutModal] = useState(false);
const [bothCount, setBothCount] = useState({});
const [currentId, setCurrentId] = useState('');

const [validateSlots, setValidateSlots] = useState([]);
const [isSlotAvailable, setIsSlotAvailable] = useState(false);
const [slotAvailableMsg, setSlotAvailableMsg] = useState('');

const getProgramPriceByDates = (allPrices, selectedDateByProgram) => {
    const _res = allPrices.filter(el => selectedDateByProgram.find((id) => el._id == id));
    let _totalPrice = 0;
    if(_res.length){
        _res.map(i => _totalPrice += i.standardPrice)
    }
    return _totalPrice;
 
}



const getProducts = () => {
    let query = `?type=2`;
    getCartProductsService(query).then(res => {
        if(res?.data?.statusCode){
            if(res?.data?.responseData?.result?.length === 0){
                setCartView(1);
            }
            setProducts(res.data.responseData.result);
            setCount(res.data.responseData.count);
        }
    })
}

useEffect(() => {
    getProducts();
}, [])


useEffect(() => {
    getCartItems().then(res => {
        if(res.data.statusCode == 0){
            setCartItems([]);
            setloader(false);        
        }else{

            if(res?.data?.responseData?.result?.length == 0){
                setCartView(2);
            }

            let detailsconfrimedCount = isDetailsConfirmed;
            let totalMoney = 0;
            let _additionalExperiencePrice = 0;
            let _associatedCartPrice = 0;
            let cartprograms = res.data.responseData.result.map(items => {

                if(items?.associatedCartInfo?.length){
                    for (let i = 0; i < items.associatedCartInfo.length; i++) {
                        _associatedCartPrice = _associatedCartPrice + (items.associatedCartInfo[i].price * items.associatedCartInfo[i].count)
                      }
                }

                if(items.detailConfirmed == 1){
                    let additionalDiscount = 0;
                    if (items.campers !== undefined) {
                        items.campers.map(camper => {
                            
                            if(camper?.additionalExperience?.length){
                                for (let i = 0; i < camper.additionalExperience.length; i++) {
                                    _additionalExperiencePrice = _additionalExperiencePrice + (camper.additionalExperience[i].price * camper.additionalExperience[i].numberOfBooking)
                                  }
                            }

                            if (camper.answers !== undefined) {
                                camper.answers.map(answ => {
                                    if (answ.options !== undefined) {
                                        answ.options.map(optio => {
                                            if(optio.opt == answ.answer){
                                                if(optio.discountType && optio.discount){
                                                    additionalDiscount = (optio.discountType == 1 ? optio.discount : ((items.registrationFee / 100) * optio.discount));
                                                } 
                                            }
                                        })
                                    }
                                })
                            }
                        }) 
                    }



                    totalMoney += (items.registrationFee * items.campers.length) + isAllFree;

                    
                    if(additionalDiscount){
                        totalMoney += totalMoney - additionalDiscount;
                    }

                    if(_additionalExperiencePrice){
                        totalMoney += _additionalExperiencePrice;
                    }

                    if(_associatedCartPrice){
                        totalMoney += _associatedCartPrice;
                    }

                    if(items.byDates == 1){
                        totalMoney += getProgramPriceByDates(items.additionalDates, items.selectedDates) + isAllFree;  
                    }


                    detailsconfrimedCount++;
                    setDetailsConfirmed(detailsconfrimedCount);
                    return {...items, additionalDiscount};
                }else {
                    return {...items};
                }

              
            })

            setIsAllFree(totalMoney);
            setloader(false);
            setCartItems(cartprograms);
        }
    })
}, [])

useEffect(() => {
    confirmedlist().then(res => {
        if(res?.data?.statusCode){
            let precartIds = [];
            let checkSlots = []
            let cartitemsDetails = res.data.responseData.result;
            cartitemsDetails.map(item => {
                if (item.detailConfirmed == 1) {
                    precartIds.push(item._id);
                    checkSlots.push({
                        programId: item.programId,
                        selectedDates: item.selectedDates,
                        noOfBookings: item.numberOfBooking        
                    })  
                }
            })

            setCartIds(precartIds);
            setValidateSlots(checkSlots);
        }
    })
}, [])



useEffect(() => {
    if(validateSlots.length){
        validateSlotsService({programData: JSON.stringify(validateSlots)}).then(res => {
            if(res.data.statusCode){
                if(res.data.responseData.result == 1){
                    setIsSlotAvailable(true)
                }else{
                    setIsSlotAvailable(false);
                    setSlotAvailableMsg(res.data.responseData.message)
                }
                
            }
        })
    }
}, [validateSlots])

const removeCartItem = async (cartId) => {
    setMiniLoader({[cartId]: true});
    const removeItem = await removeFromCart(`/?cartId=${cartId}`);
    try{
        let cartprducts = [...cartItems];
        let newcat = cartprducts.filter(cartitem => cartitem._id != cartId);
        setCartItems(newcat);
        setDetailsConfirmed(isDetailsConfirmed - 1);
        getCartItems().then(res => {
            if(res.data.statusCode == 1){
                props.getitemsincart(res.data.responseData.result.length);
                setMiniLoader({[cartId]: false});
            }
        })
    }
    catch(err){} 
}



const removeCartItem2 = () => {
    const _subproducts = products.filter(product => product.cartId === currentId).length > 0 ?  products.filter(product => product.cartId === currentId)[0]?.subproducts :null
    const subProductDeleteApis = _subproducts ? _subproducts.map(addon => deleteSubProductFromCart.bind(null, addon._id)) : null; 
    removeFromCart(`/?cartId=${currentId}`).then(res => {
        if(res.data.statusCode){
            subProductDeleteApis && Promise.allSettled(subProductDeleteApis.map(func => func()));
            setCurrentId('');
            getProducts();
            getProductsCartCount();
            notification.open({
                description:
                    'Deleted successfully',
                    placement: 'topLeft'
            })
        }else{
            setCurrentId('');
            getProducts();
            getProductsCartCount();
            notification.open({
                description:
                    'Something went wrong.',
                    placement: 'topLeft'
            })
        }
        
    });
   
}
const deleteSubProductFromCart = (currentId) => {
    
    removeFromCart(`/?cartId=${currentId}`).then(res => {
        }).catch(e => notification.open({
            description:
                'failed to add add-ons',
                placement: 'topLeft'
        }));
}


const getProductsCartCount = () => {
    getProductsCartCountService().then(res => {
        if(res?.data?.statusCode){
            props.productCartCountAction(res.data.responseData.result.itemCount);
            setBothCount(res.data.responseData.result);
        }
       
    })
}

useEffect(() => {
    getProductsCartCount();
}, []);





const submitRegistration = () => {
    let params = {
        cartIds: JSON.stringify(cartIds),
        discount: isAllFree > 0 ? '' : 1
    };
    setBtnLoader(true);
    bookingCreate(params).then(res => {
        if (res.data.statusCode == 1) {
            props.getitemsincart(props.itemsincart - 1);
            SetsuccessModel(true);

        } 
    })
}


const handleCountChange = (ind, type, c_product,) => {
    let temp = [...products];

    let params = {
        itemId: c_product.itemId,
        color: c_product.color,
        sizes: c_product.sizes,
        count: type === 'add' ? 1 : -1
    }

    if(type === 'add'){
        temp[ind].count = temp[ind].count + 1;
        addProductIntoCartService(params)
        
    }else{
        if(temp[ind].count != 1){
            temp[ind].count = temp[ind].count - 1;
            addProductIntoCartService(params)
        }
    }

    temp[ind]._price = temp[ind].price;

    setProducts(temp);
}
const handelSubItemCountChange = (index,c_product, type, parentIndex) => {
    setloader(true);
    const _allProducts = [...products];
    const _subItems = c_product.subItems.map(prevItem =>  ({ ...prevItem, count:0}));
    let temp = [...c_product?.subItems][index];
    const _tempCount = temp.count;
    if(type === 'add'){
        temp.count = 1;
    }else{
        if(temp.count > 0){
            temp.count = -1;
        }
    }
    _subItems[index] = temp;
    

    let params = {
        itemId: c_product.itemId,
        color: c_product.color,
        sizes: c_product.sizes,
        count: 0,
        subItems:[..._subItems]
    }
    addProductIntoCartService(params).then(() => {
        notification.open({
            description: 'Cart items Updated Successfully',
        });
        setloader(false);
        temp.count = (type === 'add') ? _tempCount + 1 : _tempCount-1
        c_product.subItems[index] = temp;
        _allProducts[parentIndex] = c_product;
        setProducts(_allProducts);
    }).catch(e =>{
        notification.error({
        description: 'Something went wrong',
    })
    setloader(false);
    }).finally(() => {
        setloader(false);
    })
    
    
}


const handleBookingSubmit = () => {
    setProductCheckoutModal(false);
    getProducts();
    getProductsCartCount();
    notification.open({
        description: 'Booking created successfully',
    });
}


return(
    <React.Fragment>

        <Modal
            dialogClassName="h_auto"
            show={successModel}
            animation={false}
            centered
        >
            <Modal.Body>
                <div className="p-5">
                    <h3 className="text-center mb-4">Success</h3>
                    Registration Successful
                    <Link className="btn btn-primary btn-block mt-2" to={'/registrationsuccess'}>ok</Link>

                </div>
            </Modal.Body>
        </Modal>
        {
        <Fragment>
            <div className="container">
            
                <div className="d-flex count_tag">
                    <span className={`btn btn-${cartView == 1 ? 'primary' : 'outline-dark'} px-5 mr-3`} onClick={() => setCartView(1)}>Programs <b>{bothCount.programCount}</b></span>
                    <span className={`btn btn-${cartView == 2 ? 'primary' : 'outline-dark'} px-5`} onClick={() => setCartView(2)}>Products <b>{bothCount.itemCount}</b></span>
                </div>


                {
                   cartView == 1 ? (
                    <Fragment>
                        <div className="d-flex align-items-center justify-content-between section_header">
                            <h2>Your Programs</h2>
                        </div>

                        <div className="container cart_staps px-0">
                            <p><span>Step 1: </span>Click the "Confirm Details" Button and Enter Participant Information for Each Program Below or if you have participant details already saved, simply add the participant without having to spend time re-entering the information. </p>
                            <p><span>Step 2: </span>Repeat this process once for each participant/program as needed.</p>
                            <p><span>Step 3: </span>Click "PROCEED TO PAYMENT" -&gt;  Once you've Finished Adding Participants to the selected programs</p>
                        </div>



                         {
                            loader ? (
                                <div className="program_loader"><ProgramLoader /></div>
                            ) : cartItems.length ? cartItems.map((cartitem,i) => (
                                    <Cartitems
                                        miniLoader={miniLoader}
                                        key={i}
                                        Item={cartitem}
                                        RemoveFromCart={removeCartItem}
                                        isAllFree={isAllFree} />  
                        ))
                        : <div className="no_cart_item"><h3>Cart is Empty</h3></div>
                        } 


                        {
                            isDetailsConfirmed > 0 ? 
                            <div className="text-right">
                                {
                                    parseFloat(isAllFree) > 0 ? <Link className="btn btn-primary rounded-0 ml-2 mb-2" to={'/payment-confirmation'}>Proceed to Payment</Link> : isSlotAvailable ? 
                                    <button type='submit' disabled={btnloder} onClick={submitRegistration} className="btn btn-primary rounded-0 ml-2 mb-2">Submit Registration</button> : ''
                                }
                            </div> : ''
                        }  


                        {slotAvailableMsg ? <p className="color_red">{slotAvailableMsg}</p> : ''}


                    </Fragment>
                   ) : (
                    <Fragment>
                        <div className="d-flex align-items-center justify-content-between section_header">
                            <h2>Your Products</h2>
                        </div>



                        <div className=''>
                            {
                                products.map((item, i) => (
                                    <div className="product_card_xl" key={`${item.itemId}${item?.bookingNumber}`}>

                                        <div className='remove_product_from_cart'>
                                            <i className='icon-delete' onClick={() => (setCurrentId(item.cartId))} />
                                        </div>

                                        <div className='d-flex flex-column flex-sm-row flex-wrap align-content-stretch align-items-stretch'>
                                            <div className='col-lg-4 pb-5'>
                                            {
                                                item?.itemImage?.length ? <Slider {...settings}>
                                                                        {item?.itemImage.map(img => (
                                                                                <img src={img} className="img-fluid" key={img}/>
                                                                        ))}
                                                                    </Slider> : ''
                                            }
                                            </div>
                                            <div className='col-lg-8'>
                                                <h3>{item.itemName}</h3>
                                                <div className=''>
                                                    <div  className="form-group mb-0">
                                                        <label>Size: </label>
                                                        <b> {item.sizes || 'N/A'}</b>
                                                    </div> 

                                                    <div className={`form-group mb-0`}>
                                                        <label>Color: </label>
                                                        <b> {item.color || 'N/A'}</b>
                                                    </div>

                                                    <div className={`form-group mb-0`}>
                                                        <label>Program: </label>
                                                        <b> {item.programName || 'N/A'}</b>
                                                    </div>
                                                    <div className={`form-group mb-0`}>
                                                        <label>BokingNumber: </label>
                                                        <b> {item.bookingNumber || 'N/A'}</b>
                                                    </div>
                                                    <div className={`form-group mb-0`}>
                                                        <label>Camper Name: </label>
                                                        <b> {item.camperName || 'N/A'}</b>
                                                    </div>
                                                </div>

                                                <div className="quantity mb-4 mt-0">
                                                    <button onClick={() => handleCountChange(i, 'remvoe', item)} className="inc"></button>
                                                    <b>{item.count}</b>
                                                    <button onClick={() => handleCountChange(i, 'add', item)} className="dec"></button>
                                                </div>


                                                <div className='d-flex justify-content-between align-items-center mb-3'>
                                                    <div className="product_price">
                                                        <h2><b>Base Price: ${item?._price?.toFixed(2) || (item?.price).toFixed(2)}</b></h2>   
                                                    </div>
                                                    <div className="product_price">
                                                    <h2><b>Total Price: ${ !isNonEmptyArray(item?.subItems) ? (item?.price * item?.count).toFixed(2) : item?.subItems?.filter((obj) => Object.keys(obj).length !== 0 || obj.constructor !== Object).reduce((total, addon) =>((addon?.price *addon?.count) + total), item?.price * item?.count).toFixed(2) }</b></h2>   
                                                    </div>
                                                </div>
                                                {isNonEmptyArray(item?.subItems) && 
                                                <>
                                                <h4>Add-ons</h4>
                                                <div className = "d-flex flex-column flex-sm-row flex-wrap align-content-stretch align-items-stretch">
                                                        { 
                                                            loader ? 
                                                            <div className="program_loader"><ProgramLoader /></div> :   
                                                            item?.subItems?.map ( (subItem, si) => 
                                                                <div className='product_card p-3 mx-3' key = {subItem.itemId}>
                                                                    {
                                                                        subItem?.image?.length && <img src={subItem.image[0]} className="img-fluid mx-2" style= {{width: '120px', height:"120px"}}/>
                                                                        
                                                                    }
                                                                    <div className='product_text'>
                                                                    <div className=''>
                                                                        <div style ={{width:'120px'}}>
                                                                        <h3 style={{color: subItem.textColor || '#000'}}>{subItem.itemName}</h3> 
                                                                        </div>
                                                                            <div  className="form-group mb-0">
                                                                                <label>Size: </label>
                                                                                <b> {subItem.sizes || 'N/A'}</b>
                                                                            </div> 

                                                                            <div className={`form-group mb-0`}>
                                                                                <label>Color: </label>
                                                                                <b> {subItem.color || 'N/A'}</b>
                                                                            </div>

                                                                            <div className={`form-group mb-0`}>
                                                                                <label>Program: </label>
                                                                                <b> {subItem.programName || 'N/A'}</b>
                                                                            </div>
                                                                        </div>
                                                                        <div className='pro_card_price'>
                                                                            <p>Price: ${subItem?.price?.toFixed(2)}</p>
                                                                        </div>
                                                                            
                                                                    </div>
                                                                    <div className="quantity d-flex justify-content-center mb-4 mx-3">
                                                                        <button onClick={() => handelSubItemCountChange(si,item,'remove', i)} className="inc"></button>
                                                                        <b>{subItem.count}</b>
                                                                        <button onClick={() => handelSubItemCountChange(si,item, 'add', i)} className="dec"></button>
                                                                    </div>
                                                                    
                                                                </div>
                                                            )
                                                        }   
                                                </div> 
                                                </>
                                                }
                                            </div>
                                            
                                    
                                        </div>
                                    
                                    </div>
                                ))
                            }
                        </div>


                        {
                            products.length ? <div className='d-flex justify-content-end'>
                            <button className='btn btn-primary' onClick={() => setProductCheckoutModal(true)}>Proceed to Payment</button>
                        </div> : <div className="no_cart_item"><h3>Cart is Empty</h3></div>
                        }



                        



                        <Modal
                            dialogClassName="h_auto"
                            show={productCheckoutModal}
                            animation={false}
                            size='lg'
                            centered
                        >
                            <Modal.Body>
                                <span className="modal_close" onClick={() => setProductCheckoutModal(false)}><i className="icon-cross" /></span>
                                <ProductPaymentGateway products={products} callback={handleBookingSubmit} />
                            </Modal.Body>
                        </Modal>


                    </Fragment>
                   )
                }                
            </div>

            
        </Fragment>   
        }






<ConfirmDiloag
    show={currentId}
    hideModal={() => setCurrentId('')}
    action={removeCartItem2}
    Message={["Are You Sure you want to remove this product"]} />  
       

        
        
          
    </React.Fragment>
)

}

const mapStateToProps = state => {
    let { userProfile, loggedIn } = state.user;
    let itemsincart = state.cartReducer.cartitems;
    return {
        userProfile,
        loggedIn,
        itemsincart
    };
}

const mapDispatchToProps = dispatch => ({
    getitemsincart: (cartitems) => dispatch(getitemsincart(cartitems)),
    productCartCountAction: (params) => dispatch(productCartCountAction(params)),
});


export default connect(mapStateToProps, mapDispatchToProps)(LoginUserCart);