import React from 'react'
import Questions from './Questions'

const QuestionsComponent = ({questions, setQuestions}) => {



    const handleAnswerChange = (val, index) => {
        let temp = [...questions];
        temp[index].answer = val;
        temp[index].answerErr = '';
        setQuestions([...temp]);
    }

    const handleAnswerCheckboxChange = (val, index) => {
        let temp = [...questions];
        temp[index].answer = val;
        temp[index].answerErr = '';
        temp[index].isOtherOptionSelected = 0;
        setQuestions([...temp]);
    }




    const handleIsOtherOptionClickAnswer = (index) => {
        let temp = [...questions];
        temp[index].answer = '';
        temp[index].answerErr = '';
        temp[index].isOtherOptionSelected = temp[index]?.isOtherOptionSelected ? 0 : 1;
        setQuestions([...temp]);
    }


    const handleMultipleAnswerSelect = (val, index) => {
        const temp = JSON.parse(JSON.stringify(questions));
        let _val = Array.isArray(temp[index]?.answer) ? [...temp[index]?.answer] : [];
    
        if (temp[index].isOtherOptionSelected === 1) {
            temp[index].isOtherOptionSelected = 0;
        }

        const _itemIndex = _val.indexOf(val);
    
        if (_itemIndex > -1) {
            _val.splice(_itemIndex, 1); 
        } else {
            _val.push(val);
        }

        temp[index].answer = _val;
        temp[index].answerErr = '';
        setQuestions([...temp]);
    }
  return (
    <Questions
        questions={questions}
        handleAnswerChange = {handleAnswerChange} 
        handleMultipleAnswerSelect={handleMultipleAnswerSelect} 
        handleIsOtherOptionClickAnswer={handleIsOtherOptionClickAnswer} 
        handleAnswerCheckboxChange ={handleAnswerCheckboxChange}
    />
  )
}

export default QuestionsComponent
